import { useFF } from '~/analytics/growthbook'
import { trpc } from '~/api/client'
import { holdsABDMoments } from '~/api/holdsABDMoments'
import { useAuth } from '~/auth/client'


// Used for displaying the countdown time, as well as enabling the waiting-room vs open functionality
export function useFeatureFlag() {
  const countdown = useFF('countdown_on')
  const waiting = useFF('waiting_room_on')
  const open = useFF('buy_now_open')
  const earlyAccess = useFF('early_access')
  const allowingAccess = useFF('allowing_access')
  const displayVideos = useFF('display_waiting_videos')
  const isMailChimpEnabled = useFF('display_mailchimp')
  const jawsLive = useFF('jaws_live')
  const tonyHawkLive = useFF('tony_hawk_live')
  const holdsABDMoments = useIsAbdMomentHolder()
  const activeDrop = useFF('active_drop')
  const foyLive = useFF('foy_live')
  const allAvailable = useFF('all_available')
  const yrLive = useFF('yr_live')
  const yrAuction = useFF('yr_auction')
  const yrAuctionBanner = useFF('yr_auction_banner')
  const yrSoldOut = useFF('yr_sold_out')
  const yrDisablePacks = useFF('yr_disable_packs')
  const yrLoading = useFF('yr_loading')
  const yrYoutubeBackup = useFF('yr_youtube_backup')
  const rookieLive = useFF('rookie_live')
  const yutoLive = useFF('yuto_live')
  const yutoLoading = useFF('yuto_loading')
  const andyLiveStream = useFF('andy_live_stream')
  const andyMainPart = useFF('andy_main_part')
  const andySkateTales = useFF('andy_skate_tales')
  const andySoldOut = useFF('andy_sold_out')
  const andyDayOf = useFF('andy_day_of')
  const andyEverythingIsDone = useFF('andy_everything_done')
  const mikemoLastBoard = useFF('mikemo_last_board')
  
  return {
    countdown: false, // takes highest priority, must be false for others to take effect
    // waiting: true, // takes 2nd highest priority, must be false for open to take effect
    waiting: waiting.value, // takes 2nd highest priority, must be false for open to take effect
    open: false, // lowest priority (only really needed for the redirect logic)
    earlyAccess: (earlyAccess.on && holdsABDMoments),
    earlyAccessActive: earlyAccess.on,
    allowingAccess: allowingAccess.on,
    displayVideos: displayVideos.on,
    jawsLive: jawsLive.on,
    foyLive: foyLive.on,
    tonyHawkLive: tonyHawkLive.on,
    notActiveDrop: activeDrop.on,
    allAvailable: allAvailable.on,
    yrLive: yrLive.on && yrAuction.off,
    yrAuction: yrAuction.on,
    yrAuctionBanner: yrAuctionBanner.on,
    yrSoldOut: yrSoldOut.on,
    yrDisablePacks: yrDisablePacks.on,
    yrLoading: yrLoading.on,
    yrYoutubeBackup: yrYoutubeBackup.on,
    rookieLive: rookieLive.on,
    yutoLive: yutoLive.on,
    yutoLoading: yutoLoading.on,
    andyLiveStream: andyLiveStream.on,
    andyMainPart: andyMainPart.on,
    andySkateTales: andySkateTales.on,
    andySoldOut: andySoldOut.on,
    andyDayOf: andyDayOf.on,
    andyEverythingIsDone: andyEverythingIsDone.on,
    mikemoLastBoard: mikemoLastBoard.on
  }

//   return {
//   countdown: countdown.value, // takes highest priority, must be false for others to take effect
//   waiting: waiting.value, // takes 2nd highest priority, must be false for open to take effect
//   open: open.value, // lowest priority (only really needed for the redirect logic)
// }
}

function useIsAbdMomentHolder() {
  const auth = useAuth()
  const isLoggedIn = auth.currentUser.loggedIn
  const { data } = trpc.useQuery(['holdsABDMoments', {
    address: auth.currentUser?.addr!,
  }], {
    enabled: !!isLoggedIn,
    _defaulted: false
  })

  return data
}