import { signOut, getSession } from 'next-auth/react'
import { NextRouter } from 'next/router'
import type { IAuthProviderProps } from '.'
import { fclAuthUrl, csrfUrl } from '../constants'
import { defaultCurrentUser, IAuthContextUser } from './AuthContext'
import { identifyUserForLogRocket } from '~/analytics'
import * as fcl from '@onflow/fcl'
import { addEmptyCollectionIfNotExists } from '~/flow/cadence/query'

export const loginCallback = async (logout: () => Promise<void>) => {
  try {
    const fclUser = await fcl.authenticate()
    await verifyUser(fclUser)
    const session = await getSession()

    await addEmptyCollectionIfNotExists(fclUser.addr!)

    identifyUserForLogRocket(session!.user)
    return session!.user as IAuthContextUser
  } catch (err) {
    await logout()
    console.warn(err)
    return defaultCurrentUser
  }
}

export const logoutCallback = async (
  router: NextRouter,
  props: IAuthProviderProps,
) => {
  await fcl.unauthenticate()
  const redirectUrl = getRedirect(router.pathname, props.redirectOnLogoutRoutes)
  await signOut({
    redirect: false,
  })
  if (redirectUrl) {
    router.replace(redirectUrl)
  }
}

// Set FCL's resolver function, possibly move this to config.ts
const resolver: fcl.AccountProofDataResolver = async () => {
  const appIdentifier = (await fcl.config().get('fcl.appDomainTag')) as string
  const nonce = await fetchNonce()

  return {
    appIdentifier,
    nonce,
  }
}
fcl.config().put('fcl.accountProof.resolver', resolver)

// Util for logout
function getRedirect(
  pathname: string,
  redirectOnLogoutRoutes?: LogoutRedirect[],
) {
  const paths =
    redirectOnLogoutRoutes?.map((path) => {
      if (typeof path === 'string') {
        return {
          pathname: path,
          redirectTo: '/',
        }
      }
      return path
    }) || []
  const foundPath = paths.find((path) => path.pathname === pathname)
  if (foundPath) {
    return foundPath.redirectTo
  }

  return false
}

export type LogoutRedirect =
  | string
  | {
      pathname: string
      redirectTo: string
    }

// Utils for login
async function verifyUser(
  user: fcl.CurrentUserObject,
): Promise<IAuthContextUser> {
  const accountProofService = user.services.find(
    (service) => service.type === 'account-proof',
  )
  if (!accountProofService) throw new Error('Invalid Account Proof')

  const authRes = await signInUser(user)

  return authRes
}

async function signInUser(
  user: fcl.CurrentUserObject,
): Promise<IAuthContextUser> {
  const csrfToken = await getCSRFToken()

  const data = new FormData()
  data.append('csrfToken', csrfToken)
  data.append('user', JSON.stringify(user))

  const res = await fetch(fclAuthUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(data as any), // this works but the typing is off. See: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#examples
  })

  if (res.status > 300) {
    throw new Error(res.statusText)
  }

  // Ensure login was a success
  const session = await getSession()

  if (!session) {
    throw new Error('Unable to get user session')
  }

  return session.user

  async function getCSRFToken(): Promise<string> {
    const res = await fetch(csrfUrl)
    const { csrfToken } = await res.json()

    return csrfToken
  }
}

async function fetchNonce() {
  const res = await fetch('/api/auth/nonce')
  const { nonce } = await res.json()

  return nonce
}