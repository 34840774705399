import { configureScope, setUser } from '@sentry/nextjs'
import { Session } from 'next-auth'

export const identifyUserForSentry = (user: Session['user']) => {
  setUser(user)
}

export const clearUserForSentry = () => {
  configureScope((scope) => scope.setUser(null))
}

export const setSessionUrl = (sessionURL: string) => {
  configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL)
  })
}
