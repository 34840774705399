import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import {
  useSession,
  SessionProvider,
  SessionProviderProps,
  getSession,
} from 'next-auth/react'
import * as fcl from '@onflow/fcl'
import { useRouter } from 'next/router'
import {
  AuthContext,
  defaultCurrentUser,
  IAuthContext,
  IAuthContextUser,
  useAuth,
} from './AuthContext'
import { loginCallback, logoutCallback, LogoutRedirect } from './authCallbacks'
export * from './AuthContext'
export * from './authCallbacks'
export const useUser = useAuth
// This is the main Provider that handles logic, it is simply called "inner" since we wrap the exported AuthProvider with the SessionProvider so we can useSession in here.
export const AuthProviderInner: FC<PropsWithChildren<IAuthProviderProps>> = ({
  children,
  ...props
}) => {
  const fclAuth = useFclAuth()
  const { session, setSession } = useNextAuthSession(fclAuth)
  const _session = useSession()
  const router = useRouter()

  // This is essentially the same useEffect as in useFclAuth
  // but for some reason that one runs to quick and fcl's subscribe isn't ready
  // re-running on session change seems to run it enough to work
  useEffect(() => {
    return fcl.currentUser().subscribe((fclUser) => {
      fclAuth.setCurrentUser((user: IAuthContextUser) => {
        return {
          id: user.id ?? fclUser.addr ?? fclUser.cid,
          ...fclUser,
        }
      })
    })
  }, [_session])

  const logout = useCallback(() => {
    fclAuth.setCurrentUser(defaultCurrentUser)
    return logoutCallback(router, props)
  }, [router, props])

  const login = useCallback(
    () =>
      loginCallback(logout).then((session) => {
        fclAuth.setCurrentUser(session)
        // NOTE: this is needed to get the logged in state to toggle feature flags. WTF ????
        if (session) {
          setSession({
            status: 'authenticated',
            data: {
              user: session,
              expires: new Date(session.expiresAt!).toISOString(),
            },
          })
        } else {
          setSession({
            data: session,
            status: 'unauthenticated',
          })
        }
        return session
      }),
    [logout],
  )

  const value: IAuthContext = {
    currentUser: fclAuth.currentUser,
    session,
    login,
    logout,
    signIn: login,
    signOut: logout,
  }

  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.__debug.user = session
    // @ts-ignore
    window.__debug.value = value
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export interface IAuthProviderProps {
  redirectOnLogoutRoutes?: LogoutRedirect[]
}

export const AuthProvider: FC<
  PropsWithChildren<
    IAuthProviderProps & { sessionProps?: SessionProviderProps }
  >
> = ({ sessionProps, ...props }) => {
  return (
    <SessionProvider>
      <AuthProviderInner {...props} />
    </SessionProvider>
  )
}

function useFclAuth() {
  const [currentUser, setCurrentUser] =
    useState<IAuthContextUser>(defaultCurrentUser)
  useEffect(() => {
    const unsub = fcl.currentUser().subscribe((fclUser) => {
      setCurrentUser((user: IAuthContextUser) => {
        return {
          // ...user,
          id: user.id ?? fclUser.addr ?? fclUser.cid,
          ...fclUser,
        }
      })
    })
    return () => {
      return unsub()
    }
  }, [])

  return {
    currentUser,
    setCurrentUser,
  }
}

function useNextAuthSession({
  currentUser,
}: {
  currentUser: IAuthContextUser
}) {
  const _session = useSession()
  const [session, setSession] =
    useState<ReturnType<typeof useSession>>(_session)

  useEffect(() => {
    if (currentUser.loggedIn) {
      getSession().then((__session) => {
        // @ts-ignore
        if (__session) {
          setSession({ data: __session, status: 'authenticated' })
        }
      })
    } else {
      setSession({ data: null, status: 'unauthenticated' })
    }
  }, [currentUser.loggedIn, _session.status])

  return { session, setSession }
}