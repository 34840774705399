import React, { FC, PropsWithChildren } from 'react'
import { InferMutationOutput } from '~/api/client'

export type IPackType = 'Premium' | 'Standard'
export type IPackTypeLower = Lowercase<IPackType>
export interface IPack {
  id: number
  type: IPackType
  price: number
  available: number
  total: number
}

// Handy Wrapper for FC with Children Props
export type FCC<Props = {}> = FC<PropsWithChildren<Props>>

// Extract's the type from a nullable type
export type NonNullable<T> = T extends null | undefined ? never : T

export type OpenPackInitResponse = NonNullable<
  InferMutationOutput<'openPack.init'>
>
export type OpenPackSuccessResponse = NonNullable<
  InferMutationOutput<'openPack.onSealed'>
>
export type OpenPackFailedResponse = NonNullable<
  InferMutationOutput<'openPack.onError'>
>


export enum Series {
  one = 'series-one',
  two = 'series-two',
  three = 'series-three',
  threeBox = 'series-three-box',
  tonyHawk = 'quickstrike-tony-hawk',
  jamieFoy = 'qs-jamie-foy',
  yeahRight = 'qs-yeah-right',
}

export enum Position {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum PackType {
  standard = 'Standard',
  premium = 'Premium',
}

export enum ShowSeries {
  all = 'show-all',
}

export interface IPackDisplayList {
  showSeries?: Series
  children?: React.ReactNode
}

export enum ROUTE {
  home = '/',
  packs = '/packs',
}
