import { IMoment } from '~/api/myCollection'

const videoPaths = {
  abdTrailerCompressed: `/prod-main-trailer.mp4`,
  prodSkatedShoes: '/prod-skated-shoe.mp4',
  prodOneOfOneShoe: '/shoe-one-of-one.mp4',
  prodMainTrailer: '/prod-main-trailer.mp4',
  prodRaffle: '/prod-raffle-ad.mp4',
  toreySkatedDeck: '/torey-skated-deck.gif',
  toreyLegendaryRefractor: '/torey-legendary-refractor.gif',
  toreyMainTrailer: '/torey-main-trailer.mp4',
  toreySkatedDeckVideo: '/torey-skated-deck.mp4',
  toreyLegendaryRefractorVideo: '/torey-tangible-card.mp4',
  tonyHawkReveal: '/tony-hawk-cyber-monday.mp4',
  tonyHawkStory: '/th-reveal.mp4',
  tonyHawkVaulted: '/tony-hawk-vaulted.mp4',
  jawsReveal: '/jaws-reveal.mp4',
  homepageAllSkaters: '/homepage-all-skaters.mp4',
  yeahRightVideoSummary: '/yr-video-summary.mp4',
  theBerricsTrailer: '/abd-berrics-compressed-720.mp4',
  berricsReveal7Stair: '/berrics-reveal-7-stair.mp4',
  berricsReveal5StairHubba: '/berrics-reveal-5-stair-hubba.mp4',
  berricsReveal10Stair: '/berrics-reveal-10-stair.mp4',
  berricsRevealBig4: '/berrics-reveal-big-4.mp4',
  berricsRevealBrickBanks: '/berrics-reveal-brick-banks.mp4',
  berricsRevealLedge: '/berrics-reveal-ledge.mp4',
  berricsRevealMarbleHubba: '/berrics-reveal-marble-hubba.mp4',
  berricsRevealRedCurbs: '/berrics-reveal-red-curbs.mp4',
  montageComingSoon: '/montage-coming-soon.mp4',
  newDrop: '/new-drop.mp4',
  yutoDropDateVideo: '/yuto-drop-may.mp4',
  yutoOutNowVideo: '/yuto-out-now.mp4',
  andyTrailer: '/Andy-Anderson-Trailer.mp4',
}

export const JawsReveal = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.jawsReveal}
    >
      <source src={videoPaths.jawsReveal} type='video/mp4' />
    </video>
  )
}

export const TonyHawkVaulted = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkVaulted}
    >
      <source src={videoPaths.tonyHawkVaulted} type='video/mp4' />
    </video>
  )
}

export const TonyHawkReveal = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkReveal}
    >
      <source src={videoPaths.tonyHawkReveal} type='video/mp4' />
    </video>
  )
}

export const TonyHawkStory = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.tonyHawkStory}
    >
      <source src={videoPaths.tonyHawkStory} type='video/mp4' />
    </video>
  )
}

export const ABDVideoTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.abdTrailerCompressed}
    >
      <source src={videoPaths.abdTrailerCompressed} type='video/mp4' />
    </video>
  )
}

export const MomentVideo = ({ moment }: { moment: IMoment }) => (
  <video loop controls playsInline width={'100%'} height={'100%'}>
    <source src={moment?.plays?.video_url!} type='video/mp4' />
  </video>
)

export const ProdSkatedShoe = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodSkatedShoes}
    >
      <source src={videoPaths.prodSkatedShoes} type='video/mp4' />
    </video>
  )
}

export const ProdOneOfOneShoe = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodOneOfOneShoe}
    >
      <source src={videoPaths.prodOneOfOneShoe} type='video/mp4' />
    </video>
  )
}

export const ProdRaffleVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodRaffle}
    >
      <source src={videoPaths.prodOneOfOneShoe} type='video/mp4' />
    </video>
  )
}

export const ProdMainTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.prodMainTrailer}
    >
      <source src={videoPaths.prodMainTrailer} type='video/mp4' />
    </video>
  )
}

// TODO: make this reused by the components above
export const Video = ({ src }: { src: string }) => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={src}
    >
      <source src={src} type='video/mp4' />
    </video>
  )
}

export const ToreyMainTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreyMainTrailer}
    >
      <source src={videoPaths.toreyMainTrailer} type='video/mp4' />
    </video>
  )
}

export const ToreySkatedDeckVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreySkatedDeckVideo}
    >
      <source src={videoPaths.toreySkatedDeckVideo} type='video/mp4' />
    </video>
  )
}

export const ToreyLegendaryRefractorVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.toreyLegendaryRefractorVideo}
    >
      <source src={videoPaths.toreyLegendaryRefractorVideo} type='video/mp4' />
    </video>
  )
}

export const HomepageAllSkaters = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.homepageAllSkaters}
    >
      <source src={videoPaths.homepageAllSkaters} type='video/mp4' />
    </video>
  )
}

export const YeahRightVideoSummary = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.yeahRightVideoSummary}
    >
      <source src={videoPaths.yeahRightVideoSummary} type='video/mp4' />
    </video>
  )
}

export const TheBerricsTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.theBerricsTrailer}
    >
      <source src={videoPaths.theBerricsTrailer} type='video/mp4' />
    </video>
  )
}

export const BerricsReveal7Stair = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsReveal7Stair}
    >
      <source src={videoPaths.berricsReveal7Stair} type='video/mp4' />
    </video>
  )
}

export const BerricsReveal5StairHubba = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsReveal5StairHubba}
    >
      <source src={videoPaths.berricsReveal5StairHubba} type='video/mp4' />
    </video>
  )
}

export const BerricsReveal10Stair = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsReveal10Stair}
    >
      <source src={videoPaths.berricsReveal10Stair} type='video/mp4' />
    </video>
  )
}

export const BerricsRevealBig4 = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsRevealBig4}
    >
      <source src={videoPaths.berricsRevealBig4} type='video/mp4' />
    </video>
  )
}

export const BerricsRevealBrickBanks = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsRevealBrickBanks}
    >
      <source src={videoPaths.berricsRevealBrickBanks} type='video/mp4' />
    </video>
  )
}

export const BerricsRevealLedge = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsRevealLedge}
    >
      <source src={videoPaths.berricsRevealLedge} type='video/mp4' />
    </video>
  )
}

export const BerricsRevealMarbleHubba = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsRevealMarbleHubba}
    >
      <source src={videoPaths.berricsRevealMarbleHubba} type='video/mp4' />
    </video>
  )
}

export const BerricsRevealRedCurbs = () => {
  return (
    <video
      autoPlay
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.berricsRevealRedCurbs}
    >
      <source src={videoPaths.berricsRevealRedCurbs} type='video/mp4' />
    </video>
  )
}

export const MontageComingSoon = () => {
  return (
    <video
      autoPlay
      loop
      playsInline
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.montageComingSoon}
    >
      <source src={videoPaths.montageComingSoon} type='video/mp4' />
    </video>
  )
}

export const NewDrop = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.newDrop}
    >
      <source src={videoPaths.newDrop} type='video/mp4' />
    </video>
  )
}

export const YutoDropDateVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.yutoDropDateVideo}
    >
      <source src={videoPaths.yutoDropDateVideo} type='video/mp4' />
    </video>
  )
}

export const YutoOutNowVideo = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.yutoOutNowVideo}
    >
      <source src={videoPaths.yutoOutNowVideo} type='video/mp4' />
    </video>
  )
}

export const AndyTrailer = () => {
  return (
    <video
      autoPlay
      playsInline
      loop
      width={'100%'}
      height={'100%'}
      muted
      src={videoPaths.andyTrailer}
    >
      <source src={videoPaths.andyTrailer} type='video/mp4' />
    </video>
  )
}