import Image, { ImageProps } from 'next/image'
import { IPack } from '~/interfaces'

export const imagePaths = {
  backgroundBigGlitch:
    'https://cdn.shopify.com/s/files/1/0519/9423/0953/files/background-big-glitch.jpg?v=1650260831',
  mikemoRaffleBackground: '/mo-giveaway.jpg',
  logoOfficialABD: '/ABD-logo-offical.png',
  mikemoExplosionDesktop: '/mikemo-explosion-desktop.jpg',

  mikemoLegendaryRefractorStand: '/mikemo-legendary-refractor-stand.jpg',

  prodCommonThumbnail: '/prod-thumbnail-common.jpg',
  prodRareThumbnail: '/prod-thumbnail-rare.jpg',
  prodVaultedRareThumbnail: '/prod-thumbnail-vaulted-rare.jpg',
  prodLegendaryThumbnail: '/prod-thumbnail-legendary.jpg',
  prodLegendaryRefractorThumbnail: '/prod-thumbnail-legendary-refractor.gif',

  toreyCommonThumbnail: '/torey-thumbnail-common.jpeg',
  toreyRareThumbnail: '/torey-thumbnail-rare.jpeg',
  toreyLegendaryRefractorThumbnail: '/torey-thumbnail-legendary-refractor.jpeg',
  toreySkatedDeck: '/torey-skated-deck.gif',
  toreyLegendaryRefractor: '/torey-legendary-refractor.gif',
  toreyBanner: '/series-3-banner-desktop.jpg',
  toreyBannerMobile: '/series-3-banner-mobile.jpg',

  jamieFoyBanner: '/jamie-foy-banner-desktop.jpg',
  jamieFoyBannerMobile: '/jamie-foy-banner-mobile.jpg',

  commonThumbnail: '/common-thumbnail.jpg',
  rareThumbnail: '/rare-thumbnail.jpg',
  vaultedRareThumbnail: '/vaultedRare-thumbnail.jpg',
  legendaryThumbnail: '/legendary-thumbnail.jpg',

  thGoldLegacy: '/th-gold-legacy.jpg',
  thBlackMosaic: '/th-black-mosaic.jpg',
  thRedKyber: '/th-red-kyber.jpg',
  thBlueLava: '/th-blue-lava.jpg',
  thSilverLava: '/th-silver-lava.jpg',
  thRainbowHolo: '/th-rainbow-holo.jpg',
  thSoldOut: '/th-sold-out.jpg',

  jawsGoldLegacy: '/jaws-gold-legacy.jpg',
  jawsBlackMosaic: '/jaws-black-mosaic.jpg',
  jawsRedKyber: '/jaws-red-kyber.jpg',
  jawsBlueLava: '/jaws-blue-lava.jpg',
  jawsRainbowHolo: '/jaws-rainbow-holo.jpg',

  toreyLegendaryRefractorStand: '/torey-legendary-refractor-stand.jpg',

  jamieFoyGoldLegacy: '/jamie-foy-gold-legacy.jpg',
  jamieFoyBlackMosaic: '/jamie-foy-black-mosaic.jpg',
  jamieFoyRedKyber: '/jamie-foy-red-kyber.jpg',
  jamieFoyBlueLava: '/jamie-foy-blue-lava.jpg',
  jamieFoyRainbowHolo: '/jamie-foy-rainbow-holo.jpg',

  standardPackMikeMo: '/mikemoOfficial.png',
  premiumPackMikeMo: '/premiumPack.png',

  premiumPackProd: '/prod-pack-premium.gif',
  standardPackProd: '/prod-pack-standard.gif',

  prodLegendaryCard: '/prod-legendary-card.jpg',
  prodLegendaryRefractorCard: '/prod-refractor-card.jpg',
  prodLegendaryRefractorStandCard: '/prod-legendary-refractor-stand.jpg',
  soldOut: '/sold-out.png',
  raffleImg: '/ticket-generic.png',
  blackBox: '/sealed-black.jpg',

  yeahRightBA: '/yeah-right-sample.jpg',
  yeahRightAuction: '/yr-auction.jpg',
  yeahRightAuctionMobile: '/yeah-right-auction-mobile.jpg',
  yeahRightPackaging: '/yeah-right-mobile.jpg',
  yeahRightPackagingBlack: '/yr-packaging-black-bg.jpg',
  yeahRightDeck: '/yeah-right-lenticular-deck.gif',
  yeahRightSoldOut: '/yeah-right-soldout.jpg',
  yeahRightDeckHorizontal: '/yr-deck-horizontal.jpg',

  berricsRedCurbs: '/berrics-red-curbs.jpg',
  berrics7Stair: '/berrics-7stair.jpg',
  berrics8Stair: '/berrics-8stair.jpg',
  berrics10Stair: '/berrics-10stair.jpg',
  berricsBig4: '/berrics-big4.jpg',
  berricsBrickBanks: '/berrics-brick-banks.jpg',
  berricsBrickQP: '/berrics-brick-qp.jpg',
  berricsQPCorner: '/berrics-qp-corner.jpg',
  berricsCube: '/berrics-cube.jpg',

  berricsAutoMikemo: '/berrics-auto-mikemo.jpg',
  berricsAutoMariano: '/berrics-auto-mariano.jpg',
  berricsAutoPaul: '/berrics-auto-paul.jpg',
  berricsAutoBam: '/berrics-auto-bam.jpg',
  berricsAutoBerra: '/berrics-auto-berra.jpg',
  berricsAutoJanoski: '/berrics-auto-janoski.jpg',
  berricsAutoKoston: '/berrics-auto-koston.jpg',
  berricsAutoMalto: '/berrics-auto-malto.jpg',
  berricsAutoFelipe: '/berrics-auto-felipe.jpg',
  berricsAutoJoslin: '/berrics-auto-joslin.jpg',

  julianRookieCard: '/julian-gold.jpg',

  yutoPack: '/yuto-pack.jpg',
  yutoBox: '/yuto-box.jpg',

  abdLogoWhite: '/abd-logo-white.png',
  crazyWisdomLogo: '/abd-logo-white.png',
  skateTalesLogo: '/abd-logo-white.png',

  andyBox: '/andy-box.jpg',
  andyPack: '/andy-pack.jpg',
  andyAuctionDarkslide: '/andy-darkslide-auction.jpg',

  myspaceOnlineNow: '/myspace-icon.gif',
}

const sizes = {
  sm: {
    width: '50px',
    height: '25px',
  },
  md: {
    width: '100px',
    height: '50px',
  },
  lg: {
    width: '200px',
    height: '100px',
  },
}
type Sizes = keyof typeof sizes

export const ABDLogoOfficial = ({ size = 'md' }: { size?: Sizes }) => {
  const dimensions = sizes[size]
  return (
    <Image
      src={imagePaths.logoOfficialABD}
      layout='fixed'
      height={dimensions.height}
      width={dimensions.width}
      alt='ABD Logo'
      priority
    />
  )
}

export const StandardPackImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.standardPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Standard Pack Image'}
    {...props}
  />
)

export const PremiumPackImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.premiumPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Premium Pack Image'}
    {...props}
  />
)

export const SoldOutImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.soldOut}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'Premium Pack Image'}
    {...props}
    className='sold-out'
  />
)

export const NFTThumbnail = ({
  type,
  ...props
}: { type: 'common' | 'rare' | 'vaultedRare' | 'legendary' } & Omit<
  ImageProps,
  'src'
>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const PackImageMikeMo = ({ type }: { type: IPack['type'] }) => {
  if (type === 'Premium') {
    return <PremiumPackImage />
  }

  return <StandardPackImage />
}

export const PackImageProd = ({ type }: { type: IPack['type'] }) => {
  if (type === 'Premium') {
    return (
      <>
        <PremiumPackImage src={imagePaths.premiumPackProd} />
      </>
    )
  }

  return (
    <>
      <StandardPackImage src={imagePaths.standardPackProd} />
    </>
  )
}

export const MomentImage = ({ path }: { path: string }) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src={path} alt={'Moment Image'} />
)

export const MikemoLegendaryRefractorStand = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.mikemoLegendaryRefractorStand}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Mikemo Capaldi Legendary Refractor card'}
    {...props}
  />
)

export const RevealMomentImg = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.standardPackMikeMo}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Reveal moment Image'}
    {...props}
  />
)

export const RaffleImg = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.raffleImg}
    layout={'responsive'}
    width={500}
    height={303}
    alt={'Reveal moment Image'}
    {...props}
  />
)

export type ProdCardType =
  | 'prodLegendary'
  | 'prodLegendaryRefractor'
  | 'prodLegendaryRefractorStand'
export const ProdCards = ({
  type,
  ...props
}: { type: ProdCardType } & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Card`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={'100%'}
      height={'100%'}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const ProdNFTThumbnail = ({
  type,
  ...props
}: {
  type:
    | 'prodCommon'
    | 'prodRare'
    | 'prodVaultedRare'
    | 'prodLegendary'
    | 'prodLegendaryRefractor'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', 'gif')}
      {...props}
    />
  )
}

export const ToreyNFTThumbnail = ({
  type,
  ...props
}: {
  type: 'toreyCommon' | 'toreyRare' | 'toreyLegendaryRefractor'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`${type}Thumbnail`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpeg', '')}
      {...props}
    />
  )
}

export const ToreySkatedDeck = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreySkatedDeck}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Skated Deck from The Bigger Bang Video'}
    {...props}
  />
)

export const ToreyLegendaryRefractorStand = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyLegendaryRefractorStand}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Legendary Refractor card'}
    {...props}
  />
)

export const ToreyLegendaryRefractor = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyLegendaryRefractor}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Legendary Refractor Trading Card'}
    {...props}
  />
)

export const ToreyBannerDesktop = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyBanner}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Torey Pudwill Legendary Refractor Banner Desktop'}
    {...props}
  />
)
export const ToreyBannerMobile = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.toreyBannerMobile}
    layout={'fill'}
    width={1000}
    height={1417}
    alt={'Torey Pudwill Legendary Refractor Banner Mobile'}
    {...props}
  />
)

export const BlackBox = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.blackBox}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Mystery black box'}
    {...props}
  />
)

export const BlurryImage = ({ ...props }: Omit<ImageProps, 'src'>) => {
  const imagePage = BlurryImagePaths[0]
  return (
    <Image src={imagePage} layout={'fill'} alt={'Blurry Day 1'} {...props} />
  )
}

const BlurryImagePaths = [
  '/series-3-blurry/day-1.16d8ca7a-5b52-42fe-94a2-59510a681ed7.png',
  '/series-3-blurry/day-2.f2fa91b8-a0c3-407a-820a-dfe44fb7755c.png',
  '/series-3-blurry/day-3.1b195168-4b29-4a5d-a79c-66d776c34a44.png',
  '/series-3-blurry/day-4.4949d9f1-0f5b-4829-aeb3-0b1dd2ee9419.png',
  '/series-3-blurry/day-5.b3e2b5b0-28bc-4f61-8ff8-76cefdbf12a4.png',
  '/series-3-blurry/day-6.73d81548-dbb0-4326-8814-e2aa4193ccd1.png',
  '/series-3-blurry/day-7.c2f5d5e7-8083-41b7-a754-f33936bf72a8.png',
  '/series-3-blurry/day-8.56082f29-7859-4c26-8c24-88a942f01fa0.png',
  '/series-3-blurry/day-9.c9a25c95-3563-4a2a-a456-bd22a00f1971.png',
  '/series-3-blurry/day-10.17a73867-ba07-42c5-bb5a-17f1633ccc61.png',
  '/series-3-blurry/day-11.336bcf41-2e54-4940-b490-6d092d2feca0.png',
  '/series-3-blurry/day-12.8126e3c8-6223-4760-a159-ec340335cfae.png',
  '/series-3-blurry/day-13.2309cd31-8954-4c0b-98cc-4bec105de02d.png',
  '/series-3-blurry/day-14.a43a0cab-ac25-4a64-b1e4-7fba00bee341.png',
]

export const TonyCards = ({
  type,
  ...props
}: {
  type:
    | 'RainbowHolo'
    | 'SilverLava'
    | 'BlueLava'
    | 'RedKyber'
    | 'BlackMosaic'
    | 'GoldLegacy'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`th${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JawsCards = ({
  type,
  ...props
}: {
  type: 'RainbowHolo' | 'BlueLava' | 'RedKyber' | 'BlackMosaic' | 'GoldLegacy'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`jaws${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JamieFoyCards = ({
  type,
  ...props
}: {
  type: 'RainbowHolo' | 'BlueLava' | 'RedKyber' | 'BlackMosaic' | 'GoldLegacy'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`jamieFoy${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const JamieFoyBannerDesktop = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.jamieFoyBanner}
    layout={'responsive'}
    width={350}
    height={350}
    alt={'Jamie Foy 1 of 1 Gold Legacy card with his skated truck inside'}
    {...props}
  />
)

export const JamieFoyBannerMobile = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.jamieFoyBannerMobile}
    layout={'fill'}
    width={1000}
    height={1417}
    alt={'Jamie Foy 1 of 1 Gold Legacy card with his skated truck inside'}
    {...props}
  />
)

export const YeahRightCards = ({
  type,
  ...props
}: { type: 'BA' } & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`yeahRight${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const YeahRightAuctionImage = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightAuction}
    layout={'responsive'}
    width={1400}
    height={440}
    alt={'Auctioning all 10 cards'}
    {...props}
  />
)

export const YeahRightAuctionMobile = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightAuctionMobile}
    layout={'responsive'}
    width={1200}
    height={1200}
    alt={'yeah right auction of all 10 cards'}
    {...props}
  />
)

export const YeahRightPackaging = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightPackaging}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'yeah right card'}
    {...props}
  />
)

export const YeahRightPackagingBlack = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightPackagingBlack}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'yeah right card black background'}
    {...props}
  />
)

export const YeahRightDeck = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightDeck}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'yeah right lenticular deck'}
    {...props}
  />
)

export const YeahRightDeckHorizontal = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightDeckHorizontal}
    layout={'responsive'}
    width={1014}
    height={273}
    alt={'yeah right lenticular deck'}
    {...props}
  />
)

export const YeahRightSoldOut = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yeahRightSoldOut}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'yeah right lenticular deck'}
    {...props}
  />
)

export const JulianRookieCard = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.julianRookieCard}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'Julian Rookie Card'}
    {...props}
  />
)

export const BerricsSpotImages = ({
  type,
  ...props
}: {
  type:
    | 'RedCurbs'
    | '7Stair'
    | '8Stair'
    | '10Stair'
    | 'Big4'
    | 'BrickBanks'
    | 'BrickQP'
    | 'QPCorner'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`berrics${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const BerricsAuto = ({
  type,
  ...props
}: {
  type:
    | 'Mikemo'
    | 'Paul'
    | 'Mariano'
    | 'Berra'
    | 'Janoski'
    | 'Koston'
    | 'Bam'
    | 'Malto'
    | 'Felipe'
    | 'Joslin'
} & Omit<ImageProps, 'src'>) => {
  const src = imagePaths[`berricsAuto${type}`]
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={310}
      height={310}
      alt={src.replace('.jpg', '')}
      {...props}
    />
  )
}

export const BerricsCube = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.berricsCube}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'berrics cube'}
    {...props}
  />
)
export const YutoPack = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yutoPack}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'yuto pack of cards'}
    {...props}
  />
)

export const YutoBox = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.yutoBox}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'yuto pack of cards'}
    {...props}
  />
)

export const ABDLogoWhite = ({ size = 'md' }: { size?: Sizes }) => {
  const dimensions = sizes[size]
  return (
    <Image
      src={imagePaths.abdLogoWhite}
      layout='fixed'
      height={dimensions.height}
      width={dimensions.width}
      alt='ABD Logo'
      priority
    />
  )
}

export const CrazyWisdomLogo = ({ size = 'md' }: { size?: Sizes }) => {
  const dimensions = sizes[size]
  return (
    <Image
      src={imagePaths.crazyWisdomLogo}
      layout='fixed'
      height={dimensions.height}
      width={dimensions.width}
      alt='ABD Logo'
      priority
    />
  )
}

export const SkateTalesLogo = ({ size = 'md' }: { size?: Sizes }) => {
  const dimensions = sizes[size]
  return (
    <Image
      src={imagePaths.skateTalesLogo}
      layout='fixed'
      height={dimensions.height}
      width={dimensions.width}
      alt='ABD Logo'
      priority
    />
  )
}

export const AndyBox = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.andyBox}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'Andy Anderson box of cards'}
    {...props}
  />
)

export const AndyPack = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.andyPack}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'Andy Anderson pack of cards'}
    {...props}
  />
)

export const AndyAuctionDarkslide = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.andyAuctionDarkslide}
    layout={'responsive'}
    width={'50%'}
    height={'50%'}
    alt={'yuto pack of cards'}
    {...props}
  />
)

export const MyspaceOnlineNow = (props: Partial<ImageProps>) => (
  <Image
    src={imagePaths.myspaceOnlineNow}
    layout={'responsive'}
    width={'100%'}
    height={'100%'}
    alt={'myspace online now icon'}
    {...props}
  />
)