import ReactMailchimp from 'react-mailchimp-subscribe'
import s from './mailchimp.module.scss'
import { withClassNames } from 'styles'
const cx = withClassNames(s)

const DEFAULT_URL = 'https://abdcollectibles.us21.list-manage.com/subscribe/post?u=75d59cb319d649c2ee15d1f32&id=b506866605'

export const MailChimp = ({ url = DEFAULT_URL, className = '' }) => {
  return (
    <div className={cx('mailchimp', className)}>
      <ReactMailchimp url={url} />
    </div>
  )
}
